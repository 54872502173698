@import url('https://fonts.googleapis.com/css2?family=DM+Sans:ital,opsz,wght@0,9..40,100;0,9..40,200;0,9..40,300;0,9..40,400;0,9..40,500;0,9..40,600;0,9..40,700;0,9..40,800;0,9..40,900;0,9..40,1000;1,9..40,100;1,9..40,200;1,9..40,300;1,9..40,400;1,9..40,500;1,9..40,600;1,9..40,700;1,9..40,800;1,9..40,900;1,9..40,1000&family=DM+Serif+Display:ital@0;1&display=swap');

@import "~bootstrap/dist/css/bootstrap.css";

/* IMPORTING LOCAL FONT METROPOLIS */

/* metropolis-latin-100-normal */
@font-face {
    font-family: 'Metropolis';
    font-style: normal;
    font-display: swap;
    font-weight: 100;
    src: url(https://cdn.jsdelivr.net/fontsource/fonts/metropolis@latest/latin-100-normal.woff2) format('woff2'), url(https://cdn.jsdelivr.net/fontsource/fonts/metropolis@latest/latin-100-normal.woff) format('woff');
  }
  
  /* metropolis-latin-200-normal */
  @font-face {
    font-family: 'Metropolis';
    font-style: normal;
    font-display: swap;
    font-weight: 200;
    src: url(https://cdn.jsdelivr.net/fontsource/fonts/metropolis@latest/latin-200-normal.woff2) format('woff2'), url(https://cdn.jsdelivr.net/fontsource/fonts/metropolis@latest/latin-200-normal.woff) format('woff');
  }
  
  /* metropolis-latin-300-normal */
  @font-face {
    font-family: 'Metropolis';
    font-style: normal;
    font-display: swap;
    font-weight: 300;
    src: url(https://cdn.jsdelivr.net/fontsource/fonts/metropolis@latest/latin-300-normal.woff2) format('woff2'), url(https://cdn.jsdelivr.net/fontsource/fonts/metropolis@latest/latin-300-normal.woff) format('woff');
  }
  
  /* metropolis-latin-400-normal */
  @font-face {
    font-family: 'Metropolis';
    font-style: normal;
    font-display: swap;
    font-weight: 400;
    src: url(https://cdn.jsdelivr.net/fontsource/fonts/metropolis@latest/latin-400-normal.woff2) format('woff2'), url(https://cdn.jsdelivr.net/fontsource/fonts/metropolis@latest/latin-400-normal.woff) format('woff');
  }
  
  /* metropolis-latin-500-normal */
  @font-face {
    font-family: 'Metropolis';
    font-style: normal;
    font-display: swap;
    font-weight: 500;
    src: url(https://cdn.jsdelivr.net/fontsource/fonts/metropolis@latest/latin-500-normal.woff2) format('woff2'), url(https://cdn.jsdelivr.net/fontsource/fonts/metropolis@latest/latin-500-normal.woff) format('woff');
  }
  
  /* metropolis-latin-600-normal */
  @font-face {
    font-family: 'Metropolis';
    font-style: normal;
    font-display: swap;
    font-weight: 600;
    src: url(https://cdn.jsdelivr.net/fontsource/fonts/metropolis@latest/latin-600-normal.woff2) format('woff2'), url(https://cdn.jsdelivr.net/fontsource/fonts/metropolis@latest/latin-600-normal.woff) format('woff');
  }
  
  /* metropolis-latin-700-normal */
  @font-face {
    font-family: 'Metropolis';
    font-style: normal;
    font-display: swap;
    font-weight: 700;
    src: url(https://cdn.jsdelivr.net/fontsource/fonts/metropolis@latest/latin-700-normal.woff2) format('woff2'), url(https://cdn.jsdelivr.net/fontsource/fonts/metropolis@latest/latin-700-normal.woff) format('woff');
  }
  
  /* metropolis-latin-800-normal */
  @font-face {
    font-family: 'Metropolis';
    font-style: normal;
    font-display: swap;
    font-weight: 800;
    src: url(https://cdn.jsdelivr.net/fontsource/fonts/metropolis@latest/latin-800-normal.woff2) format('woff2'), url(https://cdn.jsdelivr.net/fontsource/fonts/metropolis@latest/latin-800-normal.woff) format('woff');
  }
  
  /* metropolis-latin-900-normal */
  @font-face {
    font-family: 'Metropolis';
    font-style: normal;
    font-display: swap;
    font-weight: 900;
    src: url(https://cdn.jsdelivr.net/fontsource/fonts/metropolis@latest/latin-900-normal.woff2) format('woff2'), url(https://cdn.jsdelivr.net/fontsource/fonts/metropolis@latest/latin-900-normal.woff) format('woff');
  }

*
{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}
body
{
    font-family: 'DM Sans', sans-serif;
    color: var(--maincolor);
}

h1,h2,h3,h4,h5,h6
{
    font-family:var(--metropolis);
    margin: 0;
}

p
{
    margin: 0;
    line-height: normal;
}

a
{
    text-decoration: none;
}


:root
{
    --maincolor:#04285B;
    --scndcolor:#00B7D4;
    --metropolis:'Metropolis', sans-serif;
}

.scndry-text
{
    color: var(--scndcolor);
}

.topsec
{
    display: flex;
    flex-direction: column;
    gap:10px;
}









/* TOP NAVBAR */
.topnavbar
{
    padding:1% 5%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
}
.search-div
{
    border: solid 1px var(--maincolor);
    padding: 5px;
    border-radius: 5px;
    display: flex;
    gap: 5px;
    background: #fff;
    /* grid-template-columns: repeat(6, 1fr); */
}
/* @media screen and (max-width: 1536px) {
    .search-div
{
    grid-template-columns: repeat(3, 1fr);
}
}
@media screen and (max-width: 992px) {
    .search-div
{
    grid-template-columns: repeat(2, 1fr);
    gap: 5px;
}
} */
/* @media screen and (max-width: 768px) {
    .search-div
{
    grid-template-columns: repeat(1, 1fr);
}
} */
.search-div input
{
    border: none;
    background: #F4F9FF;
    padding:5px 10px;
    outline: none;
    color: var(--maincolor);
}
.search-div input.speciality
{
    width: 320px;
}
.search-div input.insurance
{
    width: 280px;
}
.search-div input.zip-code
{
    width: 220px;
}
.search-div input::placeholder
{
    color: var(--maincolor);
    font-size: 14px;
    opacity: .5;
}
.search-div .search-btn
{
    padding: 5px 10px;
    border-radius: 5px;
    background: var(--maincolor);
    transition: .3s ease;
}
.search-div .search-btn:hover
{
    background: var(--scndcolor);
}

/* LOGIN DROPDOWN SECTION */
.login-div
{
}
.login-div .login-btn
{
    padding: 10px 30px;
    background: linear-gradient(to right, var(--maincolor), var(--scndcolor));
    color: #fff;
    text-decoration: none;
    border-radius: 30px;
    font-weight: 500;
    text-wrap: nowrap;
}
.login-dropdown
{
    position: absolute;
    top: 80%;
    right: 5%;
    padding: 10px;
    box-shadow: 0 0 20px #00000020;
    border-radius: 25px;
    background: #fff;
    z-index: 15;
}
.login-dropdown .nav-pills
{
    padding: 5px;
    background: linear-gradient(to right, var(--maincolor), var(--scndcolor));
    border-radius: 20px;
}
.login-dropdown .nav-item button
{
    padding:2px 30px;
    border-radius: 30px;
    color: #fff;
    font-weight: 500;
}
.login-dropdown .nav-item button.active
{
    background: #fff;
    color: var(--maincolor);
}
.login-dropdown .login-signup
{
    display: flex;
    /* grid-template-columns: repeat(2, 1fr); */
    /* gap:5px; */
    align-items: center;
    /* justify-content: space-between; */
    /* gap: 5px; */
    /* padding: 0px 40px; */
    /* background: #EDF4FF; */
    border-radius: 5px;
    /* width: fit-content; */
}
.login-dropdown .login-signup .btn
{
    padding: 5px 10px;
    font-size: 16px;
    color: var(--maincolor);
    font-weight: 500;
    border-radius: 15px;
}
.login-dropdown .login-signup .btn:hover
{
    border:solid 1px var(--maincolor);
    /* color: #fff; */
}

/* SUB NAVBAR */

.sub-navbar .container-fluid
{
    padding: 0 5%;
}
.sub-navbar .navbar
{
    background: var(--maincolor) !important;
}
.sub-navbar .auto-location
{
    display: flex;
    align-items: center;
    gap:10px;
}
.sub-navbar .auto-location p, .sub-navbar .auto-location i
{
    color: #fff !important;
    margin: 0;
}
.sub-navbar .auto-location p
{
    text-decoration: underline;
    cursor: pointer;
}
/* .sub-navbar .main-navbar
{
    display: flex;
    justify-content: space-between;
    width: 100%;
} */
.sub-navbar .nav-link
{
    color:#fff !important;
    font-weight: 500 !important;
    padding: 2px 20px !important;
    border-radius: 20px;
}
.sub-navbar .nav-link:hover
{
    background: #fff;
    color: var(--maincolor) !important;
}
.sub-navbar .nav-link.active
{
    background:#fff;
    color: var(--maincolor) !important;
    /* border-radius: 20px; */
}
.sub-navbar .navbar-nav
{
    gap:40px;
}
.sub-navbar .navbar-toggler:focus
{
    box-shadow: none !important;
}
.navbar-toggler-icon
{
    display: flex;
    align-items: center;
}
.navbar-toggler
{
    border: none;
    padding: 0;
}

/* HEROSECTION */
.herosection
{
    width: 100%;
    height: calc(100vh - var(--top-header-height, 20px) - var(--sub-header-height));
    background-image:url('./assets/img/hero-section/banner-img.jpg');
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    align-items: center;
}
.herosection .right .hero-img-div
{
    position: relative;
    display: flex;
    align-items: center;
    justify-content: end;
}
.herosection .right .img-1
{
    position: absolute;
    top:0;
    right: 0;
    z-index: 1;
    animation: scale-img 6s ease-in-out infinite;
}
.herosection .right .img-2
{
    position: relative;
    z-index: 2;
}
.herosection .right .img-3
{
    position: absolute;
    top:0;
    right: 0;
    z-index: 3;
    animation: ping-pong-img 4s ease-in-out infinite;
}

@keyframes scale-img
{
    0%,100%
    {
        transform: scale(.9);
    }
    50%
    {
        transform: scale(1);
    }
}


@keyframes ping-pong-img
{
    0%,100%
    {
        transform: translateY(0);
    }
    50%
    {
        transform: translateY(-15px);
    }
}

.herosection .left
{
    display: flex;
    justify-content: center;
    align-items: start;
    flex-direction: column;
    gap: 15px;
}
.herosection .left .banner-text
{
    font-size: 54px;
    font-family: 'DM Serif Display', serif;
}
.main-para
{
    font-size: 18px;
}
.herosection .left .main-para
{
    padding-right: 100px;
}
.herosection .left a
{
    text-decoration: none;
}

.main-outline-btn
{
    display: flex;
    gap: 10px;
    align-items: center;
    padding: 12px 40px;
    border: 1px solid var(--maincolor);
    border-radius: 30px;
    font-size: 18px;
    color: var(--maincolor);
    font-weight: 600;
    transition: .3s ease;
    width: fit-content;
    font-family: var(--metropolis) !important;
    letter-spacing: .5px;
}
.main-outline-btn:hover
{
    background: var(--scndcolor);
    color: #fff;
    border: 1px solid var(--scndcolor);
}
.main-outline-btn img
{
    transition: .3s ease;
}
.main-outline-btn:hover img
{
    filter: brightness(0) invert(1);
    rotate: 45deg;
}














/* SPECIALITIES SECTION */
.specialities-section
{
    padding: 10% 0;
}

.top-head
{
    color: var(--scndcolor);
    font-size: 22px;
    font-family: 'DM Serif Display', serif;
}
.main-head
{
    font-size: 40px;
    color: var(--maincolor);
    font-family: 'DM Serif Display', serif;
    text-transform: capitalize;
}
.specialities-section .bottom-sec
{
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    margin-top:120px;
    gap:20px;
}
.specialities-section .bottom-sec .spe-items
{
    border: 2px solid var(--scndcolor);
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
    padding: 15px;
    border-radius: 30px;
}
.specialities-section .bottom-sec .icon-div
{
    background: var(--maincolor);
    padding: 20px;
    border-radius: 50%;
    margin-top: -70px;
    border: 10px solid #fff;
}
.specialities-section .bottom-sec .desc-div
{
    display: flex;
    flex-direction: column;
    align-items: center;
    gap:20px;
    padding: 20px 0;
}
.specialities-section .bottom-sec h4
{
    font-weight: 700;
    font-family:var(--metropolis);
    font-size: 24px;
}
.specialities-section .bottom-sec .main-para
{
    font-size: 16px;
    text-align: center;
    line-height: 20px;
}
.specialities-section .main-outline-btn
{
    padding: 10px 30px;
    font-size: 14px;
    line-height: normal;
}
.specialities-section .btn-sec
{
    display: flex;
    justify-content: center;
    margin-top:40px;
}
.main-fill-btn
{
    background: var(--maincolor);
    width: fit-content;
    display: flex;
    align-items: center;
    padding:12px 40px;
    border-radius: 40px;
    color: #fff;
    font-size: 18px;
    gap:10px;
    transition: .3s ease;
    font-weight: 500;
    font-family: var(--metropolis) !important;
    letter-spacing: .5px;
}
.main-fill-btn img
{
    filter: brightness(0) invert(1);
    transition: .3s ease;
}
.main-fill-btn:hover
{
    background: var(--scndcolor);
}
.main-fill-btn:hover img
{
    rotate: 45deg;
}













/* BENEFITS SECTION */
.benefits-sec
{
    padding: 0 0 10% 0;
}
.benefits-sec .bottom-sec .items
{
    margin-top: 80px;
}
.benefits-sec .bottom-sec .details-sec
{
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap:30px;
    /* padding-left: 60px; */
}
.benefits-sec .bottom-sec .tag-head
{
    background: linear-gradient(to left, #026599, var(--scndcolor));
    width: fit-content;
    border-radius: 5px;
    padding: 3px 20px;
    color: #fff;
    font-weight: 500;
}
.benefits-sec .bottom-sec .point-list
{
    display: flex;
    flex-direction: column;
    gap:20px;
}
.benefits-sec .bottom-sec .points
{
    display: flex;
    align-items: center;
    gap:20px;
}
.benefits-sec .bottom-sec .points .desc
{
    display: flex;
    flex-direction: column;
    gap: 5px;
}
.benefits-sec .bottom-sec .points h6
{
    font-family:var(--metropolis);
    font-weight: 700;
    font-size: 20px;
    text-transform: capitalize;
}
.benefits-sec .bottom-sec .points p
{
    line-height: 18px;
}
.benefits-sec .bottom-sec .main-outline-btn
{
    padding: 12px 30px;
}














/* CTASECTION */
.first-cta-section
{
    padding: 0 0 10% 0;
}
.first-cta-section .main-bg
{
    background:#E0EDFF url('./assets/img/cta-bg.png');
    background-position: center left ;
    background-size: contain;
    border-radius: 30px;
    background-repeat: no-repeat;
}
.first-cta-section .left
{
    display: flex;
    justify-content: center;
}
.first-cta-section .right
{
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap:20px;
}

/* DOCTORS SECTION */
.doctors-section
{
    padding: 0 0 10% 0;
}
.doctors-section .doctors-items
{
    display: flex;
    gap:20px;
    flex-direction: column;
}
.doctors-section .topsec .main-para
{
    width: 50%;
}
.doctors-section .doctors-items .doctors
{
    box-shadow: 0 0 10px #00000010;
    border-radius: 20px;
    overflow: hidden;
    margin: 10px;
}
.doctors-section .card-img
{
    position: relative;
    overflow: hidden;
}
.doctors-section .card-img img
{
    transition: .3s ease;
    width: 100% !important;
}
.doctors-section .card-img .recomment
{
    padding: 5px 30px;
    position: absolute;
    bottom: 20px;
    left: 0;
    background: #ffffff90;
    backdrop-filter: blur(20px);
    -webkit-backdrop-filter: blur(20px);
    font-family: var(--metropolis);
    font-weight: 600;
    border-radius: 0 20px 20px 0;
    /* font-size: 16px; */
}
.doctors-section .card-desc
{
    padding: 25px;
    display: flex;
    flex-direction: column;
    gap:20px;
}
.doctors-section .card-desc h6
{
    font-weight: 700;
    font-size: 22px;
}
.doctors-section .card-desc .designation
{
    font-weight: 500;
    font-size: 16px;
}
.doctors-section .card-desc .available
{
    display: flex;
    align-items: center;
    gap:10px;
}
.doctors-section .card-desc .available p
{
    color: #01A811;
    font-weight: 500;
}
.doctors-section .card-desc .unavailable
{
    display: flex;
    align-items: center;
    gap:10px;
}
.doctors-section .card-desc .unavailable p
{
    color: #B0B0B0;
    font-weight: 500;
}
.doctors-section .card-desc .main-btm
{
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.doctors-section .card-desc .main-btm .left
{
    gap:10px;
    align-items: center;
}
.doctors-section .card-desc .main-btm .rating
{
    font-weight: 700;
}
.doctors-section .card-desc .main-btm .review
{
    font-size: 14px;
    color: #04285b8f;
    font-weight: 600;
}
.doctors-section .card-desc .main-fill-btn
{
    padding: 8px 20px;
    font-size:14px;
    font-weight: 400;
    display: flex;
    align-items: center;
}
.doctors-section .owl-carousel .owl-item img
{
    width: unset;
}
.doctors-section .owl-carousel .owl-stage-outer
{
    /* padding: 20px 10px; */
}
.doctors-section .doctors:hover .card-img img
{
    transform: scale(1.1);
}
.doctors-section .owl-nav
{
    display: flex !important;
    gap: 15px;
    /* justify-content: end; */
    position: absolute;
    bottom: -60px;
    right: 5px;
}
.doctors-section .owl-nav .owl-next
{
    background-image:url(./assets/img/svg/carousel-arrow-r.svg) !important;
    background-size: contain !important;
    background-repeat: no-repeat !important;
    width: 45px !important;
    height: 45px !important;
    transition: .3s ease;
}
.doctors-section .owl-nav .owl-next:hover
{
    background-image:url(./assets/img/svg/carousel-arrow-r-hvr.svg) !important;
}
.doctors-section .owl-nav .owl-prev
{
    background: url(./assets/img/svg/carousel-arrow-l.svg) !important;
    background-size: contain !important;
    background-repeat: no-repeat !important;
    width: 45px !important;
    height: 45px !important;
    transition: .3s ease;
}
.doctors-section .owl-nav .owl-prev:hover
{
    background: url(./assets/img/svg/carousel-arrow-l-hvr.svg) !important;
    background-size: contain !important;
    background-repeat: no-repeat !important;
}
.doctors-section .owl-nav .owl-next span,
.doctors-section .owl-nav .owl-prev span
{
    opacity: 0;
}

.tesimonial-section
{
    padding: 5% 0;
    background: url('./assets/img/testimonial-bg.jpg');
}
.tesimonial-section .main-para{
    width: 75%;
}
.tesimonial-section .topsec h2,
.tesimonial-section .topsec p
{
    color: #fff !important;
}
.tesimonial-section .testi-items
{
    background:#fff;
    border-radius: 20px;
    /* padding:80px 30px 30px 30px; */
    padding:30px;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    gap:40px;
    height: 375px;
}
.icon-div svg path
{
    transition: .3s ease;
}
.tesimonial-section .testi-items .icon-div
{
    display: flex;
    justify-content: end;
}
.tesimonial-section .testi-items:hover .icon-div svg path
{
    fill: var(--scndcolor);
}
.tesimonial-section .testimonial-content
{
    line-height: 20px;
    font-weight: 400;
}
.tesimonial-section .testi-items img
{
    border-radius: 8px;
    width: 50px;
}
.tesimonial-section .testi-items .testi-userdiv
{
    display: flex;
    align-items: center;
    gap: 10px;
}
.tesimonial-section .testi-userdiv .testi-username
{
    font-weight: 700 !important;
    font-size: 18px;
    line-height: 20px;
}
.tesimonial-section .owl-nav
{
    margin-top: 30px;
    display: flex !important;
    gap: 15px;
    justify-content: center;
    /* position: absolute; */
    /* bottom: -60px; */
    /* right: 5px; */
}
.tesimonial-section .owl-nav .owl-next
{
    background-image:url(./assets/img/svg/testi-next.svg) !important;
    background-size: contain !important;
    background-repeat: no-repeat !important;
    width: 45px !important;
    height: 45px !important;
    transition: .3s ease;
}
.tesimonial-section .owl-nav .owl-next:hover
{
    background-image:url(./assets/img/svg/testi-next-hvr.svg) !important;
}
.tesimonial-section .owl-nav .owl-prev
{
    background: url(./assets/img/svg/testi-prev.svg) !important;
    background-size: contain !important;
    background-repeat: no-repeat !important;
    width: 45px !important;
    height: 45px !important;
    transition: .3s ease;
}
.tesimonial-section .owl-nav .owl-prev:hover
{
    background: url(./assets/img/svg/testi-prev-hvr.svg) !important;
    background-size: contain !important;
    background-repeat: no-repeat !important;
}
.tesimonial-section .owl-nav .owl-next span,
.tesimonial-section .owl-nav .owl-prev span
{
    opacity: 0;
}








.whychoose-section
{
    padding: 10% 0;
}
.whychoose-section .bottomsec
{
    padding:0 5%;
    margin-top: 60px;
}
.whychoose-section .main-para
{
    width: 75%;
}
.whychoose-section .whychoose-items
{
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap:20px;
}
.whychoose-section .whychoose-items .items
{
    display: flex;
    flex-direction: column;
    gap:20px;
    padding: 30px;
    background: #fff;
    box-shadow: 0 0 20px #00000010;
    border-radius: 30px;
    transition: .3s ease;
}
.whychoose-section .whychoose-items .items:hover img
{
    filter: brightness(0) invert(1);
}
.whychoose-section .whychoose-items .items:hover
{
    background: var(--maincolor);
}
.whychoose-section .whychoose-items .items:hover h6,
.whychoose-section .whychoose-items .items:hover p
{
    color: #fff;
}
.whychoose-section .whychoose-items .items h6
{
    font-size: 20px;
    font-weight: 700 !important;
    text-align: center;
    text-transform: capitalize;
}
.whychoose-section .whychoose-items .items p
{
    font-size: 16px;
    text-align: center;
    line-height: 18px;
}
.whychoose-section .whychoose-items .items .img-div
{
    display: flex;
    justify-content: center;
}
.whychoose-section .whychoose-items .items .img-div img
{
    width: 25%;
}














/* SECOUND CTA SECTION */
.scnd-cta-section
{
    padding: 9% 0;
    background: url('./assets/img/scnd-cta-bg.jpg') center;
    background-size: cover;
}
.scnd-cta-section .left
{
    display: flex;
    flex-direction: column;
    gap:20px;
}
.scnd-cta-section .left p
{
    line-height: normal;
}











/* UPDATES SECTION */
.updates-section
{
    padding: 10% 0;
}
.updates-section .topsec
{
    gap:20px;
}
.updates-section .topsec .top
{
    gap:10px;
}
.updates-section .right
{
    display: flex;
    align-items: center;
}
.updates-section .left
{
    display: flex;
    align-items: center;
}
.updates-section .left .img-div
{
    display: flex;
    justify-content: center;
    padding: 0 60px 0 20px;
}












/* BLOG SECTION */
.blog-section
{
    padding: 0 0 10% 0;
}
.blog-section .blog-desc
{
    padding: 20px 15px;
    display:flex;
    flex-direction: column;
    gap:15px;
}
.blog-section .blog-img-div
{
    border-radius: 20px;
    overflow: hidden;
    height: 220px;
}
.blog-section .blog-img-div img
{
    transition: .3s ease;
}
.blog-section .blog-items
{
    border: 1px solid var(--maincolor);
    padding: 10px;
    border-radius: 30px;
    margin: 1px;
}
.blog-section .blog-items:hover .blog-img-div img
{
    transform: scale(1.1);
}
.blog-section .blog-top
{
    display: flex;
    justify-content: space-between;
}
.blog-section .blog-top .category-div
{
    padding: 5px 15px;
    background: #E0FBFF;
    border-radius: 20px;
}
.blog-section .blog-top .category-div .category
{
    font-size: 14px;
    color: var(--maincolor);
    font-weight: 600;
}
.blog-section .blog-top .date-div
{
    display: flex;
    align-items: center;
    gap:10px;
}
.blog-section .blog-top .date-div .date
{
    text-wrap: nowrap;
    font-weight: 500;
}
.blog-section .blog-details .blog-title
{
    font-size: 20px;
    font-weight: 700;
    text-transform: capitalize;
}
.blog-section .blog-details .blog-para
{
    margin-top:5px;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
}
.blog-section .read-more-btn
{
    display: flex;
    font-weight: 700;
    color: var(--maincolor);
    gap: 10px;
    font-size:18px;
}
.blog-section .read-more-btn img
{
    width: unset;
    transform: rotate(45deg);
    transition: .3s ease;
}
.blog-section .blog-items:hover .read-more-btn img
{
    transform: rotate(0);
}
.blog-section .owl-nav
{
    margin-top: 30px;
    display: flex !important;
    gap: 15px;
    /* justify-content: end; */
    position: absolute;
    bottom: -60px;
    right: 5px;
}
.blog-section .owl-nav .owl-next
{
    background-image:url(./assets/img/svg/blog-next.svg) !important;
    background-size: contain !important;
    background-repeat: no-repeat !important;
    width: 45px !important;
    height: 45px !important;
    transition: .3s ease;
}
.blog-section .owl-nav .owl-next:hover
{
    background-image:url(./assets/img/svg/blog-next-hvr.svg) !important;
}
.blog-section .owl-nav .owl-prev
{
    background: url(./assets/img/svg/blog-prev.svg) !important;
    background-size: contain !important;
    background-repeat: no-repeat !important;
    width: 45px !important;
    height: 45px !important;
    transition: .3s ease;
}
.blog-section .owl-nav .owl-prev:hover
{
    background: url(./assets/img/svg/blog-prev-hvr.svg) !important;
    background-size: contain !important;
    background-repeat: no-repeat !important;
}
.blog-section .owl-nav .owl-next span,
.blog-section .owl-nav .owl-prev span
{
    opacity: 0;
}
.blog-section .main-fill-btn
{
    padding: 12px 30px;
}
.blog-section .blogmain
{
    margin-top: 45px;
}












/* FOOTER CTA SECTION */
.footer-section
{
    padding: 10% 0 0 0;
}
.footer-section .footer-cta
{
    background: url('./assets/img/footer-cta-bg.jpg');
    background-size: cover;
    background-repeat: no-repeat;
    padding: 9% 5%;
    border-radius: 30px;
    position: relative;
}
.footer-section .footer-cta .left
{
    display: flex;
    flex-direction: column;
    gap:20px;
}
.footer-section .footer-cta .right .img-div
{
    position: absolute;
    bottom: 0;
    right: 140px;
}

.footer-section .footer-main
{
    padding: 15% 0 5% 0;
    border-radius: 40px;
    background: linear-gradient(0deg, rgb(0, 26.82, 64.81) 0%, rgb(0, 45.11, 73.31) 52.48%);
    margin:-10% 3% 0;
}
.footer-section .footer-main .footer-head
{
    font-family: 'DM Serif Display', serif;
    color: #fff;
    /* font-size: 24px; */
}
.footer-section .footer-main .top-head
{
    display: flex;
    gap:40px;
}
.footer-section .footer-main .top-head i
{
    color: #fff;
    font-size:18px;
}
.footer-section .footer-main .navbar
{
    background:transparent !important;
    padding: 0;
}
.footer-section .footer-main .navbar-nav
{
    flex-direction: column;
    gap:10px;
}
.footer-section .footer-main .navbar-nav .nav-link
{
    padding: 0;
    color:#fff;
    font-size: 18px;
}
.footer-section .footer-main .footer-details
{
    display: flex;
    flex-direction: column;
    gap:60px
}
.footer-section .footer-main .details-item .details
{
    display: flex;
    flex-direction: column;
    gap:15px;
}
.footer-section .footer-main .details-item .details a
{
    color: #fff;
    display: flex;
    align-items: center;
    gap:10px;
}
.footer-section .footer-main .social-links
{
    flex-direction: row !important;
    gap: 25px !important;
}
.footer-section .footer-main .social-links i
{
    font-size: 22px;
    transition: .3s ease;
}
.footer-section .footer-main .social-links i:hover
{
    color: var(--scndcolor);
}



.footer-section .bottom-footer
{
    padding: 30px 0 20px;
}
.footer-section .bottom-footer .main
{
    display: flex;
    justify-content: space-between;
}
.footer-section .bottom-footer .newsletter-sec
{
    display: flex;
    align-items: end;
    justify-content: end;
}
.footer-section .bottom-footer .newsletter-div
{
    display: flex;
    flex-direction: column;
    align-items: start;
}
.footer-section .bottom-footer .newsletter-sec h4
{
    font-family: 'DM Serif Display', serif;
}
.footer-section .bottom-footer .newsletter-sec input
{
    border: none;
    width: 300px;
    outline: none;
}
.footer-section .bottom-footer .newsletter-sec input:focus
{
    border: none;
    box-shadow: none;
}
.footer-section .bottom-footer .newsletter-sec .input-div
{
    padding:5px 5px 5px 20px;
    border: 1px solid var(--maincolor);
    border-radius: 40px;
    margin-top: 10px;
    display: flex;
    align-items: center;
}
.footer-section .bottom-footer .newsletter-sec input::placeholder
{
    color: #04285B;
    font-size: 14px;
}
.footer-section .bottom-footer .newsletter-sec .newsletter-submit
{
    padding: 5px 30px;
    background: var(--maincolor);
    border-radius: 20px;
    color: #fff;
    transition: .3s ease;
}
.footer-section .bottom-footer .newsletter-sec .newsletter-submit:hover
{
    background: var(--scndcolor);
}


.footer-section .copyright
{
    display:flex;
    justify-content: space-between;
    padding-bottom: 10px;
}
.footer-section .copyright .sitemap-details
{
    display: flex;
    justify-content: end;
    gap:20px
}
.footer-section .copyright .sitemap-details a
{
    font-size: 16px;
    color: var(--maincolor);
}
.footer-section .copyright .main-para
{
    font-size: 16px;
}
.footer-section .copyright-sec .mobile
{
    display: none;
}






















/* SEARCH RESULTS */
.searchresult-sec
{
    padding:2% 5% 6%;
}
.searchresult-sec .left
{
    /* padding-right: 60px; */
}
.searchresult-sec .topsec .main-head
{
    display: flex;
    align-items: center;
    gap: 20px;
}
.searchresult-sec .search-main
{
    margin-top:40px
}
.searchresult-sec .search-main .recommend-sec
{
    font-size: 14px;
    padding: 8px 20px;
    background: linear-gradient(to right, var(--maincolor), var(--scndcolor));
    color: #fff;
    width: fit-content;
    height: fit-content;
    border-radius: 20px;
    font-family: var(--metropolis);
    font-weight: 500;
    letter-spacing: .5px;
    line-height: normal;
}
.searchresult-sec .search-main  .img-sec
{
    display: flex;
    justify-content: space-between;
    position: relative;
}
.searchresult-sec .search-main  .img-sec img
{
    /* position: absolute; */
    margin-top:-70px;
    border-radius: 10px;
}
.searchresult-sec .search-main .results-sec
{
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    margin-top: 100px;
    gap: 100px 35px;
}
.searchresult-sec .search-main .result-doctors
{
    border:1px solid var(--maincolor);
    padding: 20px;
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap:20px;
    margin: 1px;
}
.searchresult-sec .search-main .name-sec h4
{
    font-size: 18px;
    font-weight: 700; 
    width: 90%;   
    text-transform: capitalize;
}
.searchresult-sec .search-main .rating-sec
{
    display: flex;
}
.searchresult-sec .search-main .rating-sec .rating
{
    font-weight: 700;
    margin:0 20px 0 10px;
}
.searchresult-sec .search-main .rating-sec .review
{
    font-size: 14px;
    color: #04285b8f;
    font-weight: 600;
}
.searchresult-sec .main-fill-btn
{
    padding:10px 20px;
    width: 100%;
    text-align: center;
    border-radius: 10px;
    justify-content: center;
}
.searchresult-sec .available
{
    display: flex;
    align-items: center;
    gap:10px;
}
.searchresult-sec .available p
{
    color: #01A811;
    font-weight: 500;
}
.searchresult-sec .unavailable
{
    display: flex;
    align-items: center;
    gap:10px;
}
.searchresult-sec .unavailable p
{
    color: #B0B0B0;
    font-weight: 500;
}
.searchresult-sec .right-ad
{
    display: flex;
    align-items: end;
}
.searchresult-sec .right-ad img
{
    border-radius: 20px;
}
.searchresult-sec .doc-desc p
{
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
}
.searchresult-sec .owl-carousel .owl-item img
{
    width: unset;
}

.searchresult-sec .owl-nav
{
    margin-top: 30px;
    display: flex !important;
    gap: 15px;
    /* justify-content: end; */
    position: absolute;
    bottom: -60px;
    right: 5px;
}
.searchresult-sec .owl-nav .owl-next
{
    background-image:url(./assets/img/svg/blog-next.svg) !important;
    background-size: contain !important;
    background-repeat: no-repeat !important;
    width: 45px !important;
    height: 45px !important;
    transition: .3s ease;
}
.searchresult-sec .owl-nav .owl-next:hover
{
    background-image:url(./assets/img/svg/blog-next-hvr.svg) !important;
}
.searchresult-sec .owl-nav .owl-prev
{
    background: url(./assets/img/svg/blog-prev.svg) !important;
    background-size: contain !important;
    background-repeat: no-repeat !important;
    width: 45px !important;
    height: 45px !important;
    transition: .3s ease;
}
.searchresult-sec .owl-nav .owl-prev:hover
{
    background: url(./assets/img/svg/blog-prev-hvr.svg) !important;
    background-size: contain !important;
    background-repeat: no-repeat !important;
}
.searchresult-sec .owl-nav .owl-next span,
.searchresult-sec .owl-nav .owl-prev span
{
    opacity: 0;
}



.searchresult-sec .owl-nav .owl-prev.disabled,
.searchresult-sec .owl-nav .owl-next.disabled {
    opacity: 0.5; /* Adjust opacity as desired */
    cursor: not-allowed;
    pointer-events: none;
}































/* RESPONSIVE CSS */
@media screen and (max-width: 1536px)
{
    .topnavbar
    {
        padding: 1% 3%;
        flex-wrap: wrap;
    }
    .login-div
    {
        display: flex;
        justify-content: end;
    }

    
    .sub-navbar .container-fluid
    {
        padding: 0 3%;
    }
    .login-div .login-btn
    {
        padding: 8px 20px;
    }

    
    /* .search-div input
    {
        width: 250px;
    } */
    .search-div input.speciality
    {
        width: 320px;
    }
    .search-div input.insurance
    {
        width: 280px;
    }
    .search-div input.zip-code
    {
        width: 220px;
    }
    .search-div input::placeholder
    {
        /* font-size: 12px; */
    }
    .sub-navbar .navbar-nav
    {
        gap:10px;
    }
    .herosection .right
    {
        padding: 80px;
    }
    .benefits-sec .bottom-sec .img-sec
    {
        padding: 40px;
    }
    .scnd-cta-section
    {
        padding: 6% 0;
    }
    .updates-section .left .img-div
    {
        padding: 0 80px;
    }


    .searchresult-sec
    {
        padding: 2% 3% 8%;
    }

    .searchresult-sec .search-main
    {
        margin-top:60px;
    }
    .searchresult-sec .search-main .recommend-sec
    {
        font-size: 10px;
    }
    .searchresult-sec .search-main .result-doctors
    {
        padding: 15px;
        gap: 10px;
    }
    .searchresult-sec .search-main .results-sec
    {
        margin-top:60px;
    }
    .searchresult-sec .doc-desc p
    {
        font-size: 14px;
    }
    .searchresult-sec .main-fill-btn
    {
        font-size: 16px;
    }



}



@media only screen and (max-width: 992px)
{
    .search-div
    {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        width: 100%;
    }
    .search-div input
    {
        width: 100% !important;
    }
    .search-div input::placeholder
    {
        font-size: 12px;
    }
    .herosection .right
    {
        padding: 0 80px 80px;
    }
    .search-btn
    {
        display: flex;
        align-items: center;
        justify-content: center;
        gap:20px;
        flex-direction: row-reverse;
    }
    .search-btn p
    {
        display: block;
        font-size: 16px;
        color: #fff;
    }

    .searchresult-sec .search-main .results-sec
    {
        grid-template-columns: repeat(2, 1fr);
    }
    .searchresult-sec .right-ad
    {
        align-items: center;
    }




    .offcanvas
    {
        padding: 10px 20px;
    }
    .offcanvas .navbar-nav
    {
        margin-top: 40px;
        gap: 30px;
    }
    .offcanvas .navbar-nav .nav-link
    {
        color: var(--maincolor) !important;
        font-size: 22px;
    }




    .herosection .left
    {
        align-items: center;
    }
    .herosection .left .main-para
    {
        text-align: center;
        padding: 0 60px;
    }



    .specialities-section
    {
        padding: 15% 0;
    }
    .specialities-section .bottom-sec
    {
        grid-template-columns: repeat(2, 1fr);
        gap:80px 50px;
    }


    .benefits-sec
    {
        padding: 0 0 15% 0;
    }
    .benefits-sec .bottom-sec .items
    {
        margin-top: 50px;
    }
    .benefits-sec .bottom-sec
    {
        padding: 0 40px;
    }
    .benefits-sec .bottom-sec .details-sec
    {
        margin-top:40px;
    }


    .first-cta-section
    {
        padding: 0 0 15% 0;
    }
    .first-cta-section .main-head
    {
        font-size: 28px;
    }
    .first-cta-section .main-bg
    {
        background-position: -180px center;
    }



    
    .doctors-section
    {
        padding: 0 0 15% 0;
    }
    .doctors-section .topsec .main-para
    {
        width: 75%;
    }
    .doctors-section .card-desc
    {
        padding: 15px;
    }
    .doctors-section .card-desc .main-btm .review
    {
        font-size: 12px;
        text-wrap: nowrap;
    }
    .doctors-section .card-desc .main-btm .rating
    {
        font-size: 14px;
    }
    .doctors-section .card-desc .main-fill-btn
    {
        padding: 8px 14px;
        font-size: 12px;
        text-wrap: nowrap;
    }
    .doctors-section .main-outline-btn
    {
        padding: 12px 30px;
    }



    .tesimonial-section
    {
        padding: 10% 0;
    }
    .tesimonial-section .testi-items
    {
        gap:20px;
    }
    .tesimonial-section .main-para
    {
        width: 100%;
    }



    .whychoose-section
    {
        padding: 15% 0;
    }
    .whychoose-section .main-para
    {
        width: 100%;
    }
    .whychoose-section .whychoose-items
    {
        grid-template-columns: repeat(2, 1fr) !important;
    }
    .whychoose-section .whychoose-items .items
    {
        padding: 24px 30px;
    }


    .scnd-cta-section .main-head
    {
        font-size: 36px;
    }



    .updates-section
    {
        padding: 15% 0;
    }
    .updates-section .right
    {
        margin-top:80px;
    }


    .blog-section
    {
        padding: 0 0 18% 0;
    }
    .blog-section .blog-details .blog-title
    {
        font-size: 16px;
    }
    .blog-section .blog-desc
    {
        padding: 14px;
    }
    .blog-section .blog-details .blog-para
    {
        margin-top: 10px;
    }

    

    .footer-section .footer-cta .right .img-div
    {
        right: 20px;
        display: flex;
        justify-content: end;
    }
    .footer-section .footer-cta .footer-cta-img
    {
        width: 70%;
    }
    .footer-section .footer-cta .main-head
    {
        font-size: 36px;
    }
    



    .footer-section .bottom-footer .main .logo-section
    {
        display: flex;
        align-items: center;
        
    }
    .footer-section .bottom-footer .main .logo-section img
    {
        width: 80%;
    }
    .footer-main .collapse:not(.show)
    {
        display: block;
    }
    .footer-section .footer-main
    {
        padding: 20% 5% 8% 5%;
    }

/* .footer-section .bottom-footer .newsletter-sec
    {
        justify-content: end;
    } */
    .footer-section .bottom-footer .newsletter-sec input
    {
        width: 200px;
    }

    .login-dropdown
    {
        top:40%;
        right: 3%;
    }


    .searchresult-sec .topsec
    {
        margin-top: 30px;
    }
    .searchresult-sec .right-ad
    {
        display: flex;
        justify-content: center;
        margin-top:100px;
    }
}



@media only screen and (max-width: 600px)
{


    .login-dropdown
    {
        top:30%
    }
    .login-dropdown .nav-item button
    {
        font-size: 14px;
    }
    .login-dropdown .login-signup .btn
    {
        font-size: 14px;
    }

    .search-div
    {
        grid-template-columns: repeat(1, 1fr);
        margin-top: 10px;
    }
    .topnavbar
    {
        padding: 3% 5%;
    }
    .login-div .login-btn
    {
        font-size: 12px;
        padding: 4px 20px;
    }
    .search-div input
    {
        font-size: 12px;
    }
    .search-btn p
    {
        font-size: 14px;
    }
    .topnavbar .logo-main
    {
        width: 50%;
    }
    .topnavbar .logo-main img
    {
        /* width: 90%; */
    }
    
    .sub-navbar .auto-location p, .sub-navbar .auto-location i
    {
        font-size: 14px;
    }
    .sub-navbar .navbar
    {
        padding: 56t px 0;
    }



    .herosection
    {
        height:70vh;
    }
    .herosection .container
    {
        padding: 0 6%;
    }
    .herosection .right
    {
        padding: 20px;
    }
    .herosection .row
    {
        gap: 20px;
    }
    .herosection .left .banner-text
    {
        font-size: 32px;
        text-align: center;
    }
    .herosection .left .main-para
    {
        padding: 0;
    }

    .main-para
    {
        font-size: 16px;
        line-height: normal;
    }
    .main-head
    {
        font-size:26px;
    }
    .top-head
    {
        font-size: 16px;
    }
    .main-outline-btn
    {
        font-size: 14px;
        padding: 10px 30px;
    }
    .main-fill-btn
    {
        font-size: 14px;
    }
    .main-fill-btn img,
    .main-outline-btn img
    {
        display: none;
    }


    

    .specialities-section
    {
        padding: 20% 0;
    }
    .specialities-section .container
    {
        /* padding: 0 20px; */
    }
    .specialities-section .bottom-sec
    {
        grid-template-columns: repeat(1, 1fr);
        margin-top:100px;
        padding: 0 30px;
    }
    .specialities-section .bottom-sec .spe-items
    {
        gap:0px;
        padding: 0 20px;
    }
    .specialities-section .bottom-sec .desc-div
    {
        gap: 15px;
        padding: 10px 0 20px 0;
    }
    .specialities-section .main-fill-btn
    {
        padding:10px 30px;
        font-size: 14px;
    }
    .specialities-section .bottom-sec h4
    {
        font-size: 20px;
    }
    .specialities-section .bottom-sec .icon-div
    {
        margin-top: -60px;
    }
    .specialities-section .main-outline-btn
    {
        font-size: 12px;
        padding: 8px 30px;
    }

    .topsec
    {
        padding: 0 20px;
    }




    .benefits-sec
    {
        padding: 0 0 20% 0;
    }
    .benefits-sec .bottom-sec .img-sec
    {
        padding: 0;
    }
    .benefits-sec .bottom-sec .points
    {
        gap:10px;
    }
    .benefits-sec .bottom-sec .points h6
    {
        font-size: 16px;
    }
    .benefits-sec .bottom-sec .points p
    {
        font-size: 14px;
    }
    .benefits-sec .bottom-sec
    {
        padding: 0 30px;
    }
    .benefits-sec .bottom-sec .points .desc
    {
        gap: 0;
    }
    .benefits-sec .bottom-sec .tag-head p
    {
        font-size: 14px;
    }
    .benefits-sec .bottom-sec .main-outline-btn   
    {
        padding: 10px 30px;
    }
    




    .first-cta-section {
        padding: 0 0 20% 0;
    }
    .first-cta-section .left img
    {
        display: none;
    }
    .first-cta-section .container
    {
        padding: 0;
    }
    .first-cta-section .main-bg
    {
        border-radius: 0;
        padding: 60px 30px;
        background-blend-mode: darken;
    }
    .first-cta-section .main-head
    {
        text-align: center;
        font-size: 24px;
    }
    .first-cta-section .main-head br
    {
        display: none;
    }
    .first-cta-section .right
    {
        align-items: center;
    }






    .doctors-section .topsec .main-para
    {
        width: 100%;
    }
    .doctors-section .topsec
    {
        /* padding: 0 10px; */
    }
    .doctors-section .card-img .recomment
    {
        font-size: 12px;
    }
    .doctors-section .card-desc h6
    {
        font-size: 18px;
    }
    .doctors-section .card-desc .designation
    {
        font-size: 14px;
    }
    .doctors-section .card-desc .available p
    {
        font-size: 14px;
    }
    .doctors-section .card-desc .main-fill-btn
    {
        padding: 8px 20px;
    }
    .doctors-section .desc-bottom img
    {
        width: 14px !important;
    }
    .doctors-section .main-outline-btn
    {
        /* padding: 1px 20px; */
    }
    .doctors-section .owl-nav
    {
        gap:10px;
    }
    .doctors-section .owl-nav .owl-prev,
    .doctors-section .owl-nav .owl-next
    {
        background-size: 40px !important;
    }
    .doctors-section .owl-nav .owl-prev:hover,
    .doctors-section .owl-nav .owl-next:hover
    {
        background-size: 40px !important;
    }







    .tesimonial-section .testi-items
    {
        padding: 20px;
        height: 320px;
    }
    .tesimonial-section .owl-nav .owl-prev,
    .tesimonial-section .owl-nav .owl-next
    {
        background-size: 40px !important;
    }
    .tesimonial-section .owl-nav .owl-prev:hover,
    .tesimonial-section .owl-nav .owl-next:hover
    {
        background-size: 40px !important;
    }







    .whychoose-section
    {
        padding:20% 0;
    }
    .whychoose-section .whychoose-items {
        grid-template-columns: repeat(1, 1fr) !important;
    }
    .whychoose-section .whychoose-items .items h6
    {
        font-size: 18px;
    }
    .whychoose-section .whychoose-items .items
    {
        padding: 30px 20px;
    }





    .scnd-cta-section
    {
        background-position: left;
        padding: 12% 0;
    }
    .scnd-cta-section .main-head
    {
        font-size: 28px;
        text-align: center;
    }
    .scnd-cta-section .left p
    {
        text-align: center;
    }
    .scnd-cta-section .left
    {
        align-items: center;
    }





    .updates-section
    {
        padding: 20% 0;
    }
    .updates-section .left .img-div
    {
        padding: 0 20px;
    }



    .blog-section .main-head
    {
        padding: 0 30px;
    }
    .blog-section .blogmain
    {
        margin-top: 20px;
    }
    
    



    .footer-section .right img
    {
        display: none;
    }
    .footer-section .footer-cta .main-head
    {
        font-size: 28px;
        text-align: center;
    }
    .footer-section .footer-cta .left
    {
        align-items: center;
    }
    .footer-section .footer-cta
    {
        padding: 15% 5%;
    }
    .footer-section .footer-main
    {
        margin:20% 3% 0
    }
    .footer-section .footer-main
    {
        padding: 20% 10%;
    }
    .footer-section .row
    {
        gap: 20px;
    }
    .footer-section .footer-main .footer-details
    {
        gap:40px;
    }
    .footer-section .bottom-footer .main .logo-section
    {
        justify-content: center;
    }
    .footer-section .bottom-footer .newsletter-sec
    {
        justify-content: center;
    }
    .footer-section .bottom-footer .newsletter-sec h4
    {
        text-align: center;
    }
    .footer-section .bottom-footer .newsletter-div
    {
        align-items: center;
    }
    .footer-section .bottom-footer .main
    {
        flex-direction: column-reverse;
    }
    .footer-section .copyright
    {
        display: flex;
        flex-direction: column;
    }
    .footer-section .copyright .main-para
    {
        text-align: center;
    }
    .footer-section .copyright .sitemap-details
    {
        justify-content: center;
    }
    .footer-section .copyright-sec .desktop
    {
        display: none;
    }
    .footer-section .copyright-sec .mobile
    {
        display: block;
        margin:10px 0 20px 0;
    }






    .searchresult-sec
    {
        padding: 5% 3% 8%;
    }
    .searchresult-sec .search-main .results-sec
    {
        grid-template-columns:repeat(2, 1fr);
        gap:70px 5px;
    }
    .searchresult-sec .search-main .name-sec h4
    {
        font-size: 12px;
    }
    .searchresult-sec .search-main .img-sec img
    {
        margin-top: -50px;
        width: 72px !important;
        height: 72px !important;
    }
    .searchresult-sec .topsec .main-head
    {
        font-size: 20px !important;
    }
    .searchresult-sec .topsec .main-head img
    {
        width: 20px;
        /* height: 16px; */
    }
    .searchresult-sec .search-main
    {
        margin-top: 10px;
    }
    .searchresult-sec .search-main .name-sec p
    {
        font-size: 12px;
    }
    .searchresult-sec .search-main .result-doctors
    {
        border-radius:10px;
    }
    .searchresult-sec .doc-desc p
    {
        font-size: 10px;
    }
    .searchresult-sec .search-main .rating-sec .rating
    {
        font-size: 12px;
        margin: 0 10px 0 5px
    }
    .searchresult-sec .search-main .rating-sec
    {
        align-items: center;
    }
    .searchresult-sec .search-main .rating-sec img
    {
        width: 14px;
    }
    .searchresult-sec .search-main .rating-sec .review
    {
        font-size: 12px;
    }
    .searchresult-sec .main-fill-btn
    {
        font-size: 10px;
        padding: 8px 10px;
        border-radius: 5px;
    }
    .searchresult-sec .search-main .recommend-sec
    {
        font-size: 6px;
        padding: 5px 10px;
    }
    .searchresult-sec .search-main .result-doctors
    {
        padding: 8px;
    }
    .searchresult-sec .available-sec img
    {   
        width: 14px !important;
    }
    .searchresult-sec .available-sec p
    {
        font-size: 12px;
    }
    .searchresult-sec .available-sec
    {
        gap:5px;
    }
    .searchresult-sec .right-ad
    {
        margin-top: 100px;
    }






    .footer-main .collapse:not(.show)
    {
        display: none;
    }
}

@media only screen and (max-width: 380px)
{
    .topnavbar .logo-main img
    {
        /* width: 80%; */
    }
    .herosection .left .banner-text
    {
        font-size: 28px;
    }


    .searchresult-sec .search-main .img-sec img
    {
        width: 60px !important;
        height: 60px !important;
        margin-top: -40px;
    }
    .searchresult-sec .available, .searchresult-sec .unavailable
    {
        margin-bottom: 0 !important;
        gap: 5px;
    }
    .searchresult-sec .search-main .rating-sec .review
    {
        font-size: 12px;
    }

    .herosection
    {
        height: 90vh !important;
    }

    .first-cta-section .main-bg
    {
        padding: 35px 30px;
    }

    .footer-section .copyright .main-para,
    .footer-section .copyright .sitemap-details a
    {
        font-size: 14px;
        margin-bottom: 10px;
    }

    
}










































/* REGISTRATION FORM SECTION */
.reg-form-sec
{
    padding:2% 5% 6%;
}
.reg-form-sec .left
{
    /* padding-right: 60px; */
}
.reg-form-sec .topsec .main-head
{
    display: flex;
    align-items: center;
    gap: 20px;
}
.reg-form-sec .form-main
{
    margin-top:40px;
}
.reg-form-sec .form-main .form-section
{
    border: 1px solid var(--maincolor);
    border-radius: 20px;
}
.reg-form-sec .form-section
{
    padding: 40px 80px;
}
.reg-form-sec .form-section form
{
    margin-top: 50px;
    display: flex;
    flex-direction: column;
    gap:50px;
}
.reg-form-sec .form-section .form-groups
{
    display: flex;
    flex-direction: column;
    gap:20px;
}
.reg-form-sec .form-section .form-groups h3
{
    font-size: 22px;
    font-weight: 700;
}
.reg-form-sec .input-div
{
    display: flex;
    flex-direction: column;
    gap:5px;
}
.reg-form-sec .input-div .label
{
    font-weight: 600;
}
.reg-form-sec .input-div .innerlabel
{
    font-weight: 400 !important;
}
.reg-form-sec .input-div input
{
    border: 1px solid var(--maincolor);
    background:#F4F9FF;
    padding:5px 14px;
    border-radius: 5px;
}
.reg-form-sec .input-div input::placeholder
{
    color: #04285b4a;
    /* font-size: 14px; */
}
.reg-form-sec .input-div .radio-div
{
    display: flex;
    gap:20px;
    padding: 5px 0px;
}
.reg-form-sec .input-div div
{
    width: fit-content;
    display: flex;
    gap:10px;
}
.reg-form-sec .input-div input[type="date"]
{
    /* color: #00000010; */
}
.reg-form-sec .input-div select
{
    border: 1px solid var(--maincolor);
    background:#F4F9FF;
    padding:5px 10px;
    border-radius: 5px;
}
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    appearance: none;
    margin: 0;
}
.reg-form-sec .input-div .upload
{
    position: relative;
    width: 100%;
}
.reg-form-sec .input-div .upload-resume
{
    position: absolute;
    width:100%;
    background: transparent;
    border: 1px solid var(--maincolor);
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
    font-size: 14px;
    padding: 2px 70px;
    gap:20px;
}
.reg-form-sec .form-section form .button
{
    display: flex;
    justify-content: center;
}
.reg-form-sec .form-section form button
{
    background: linear-gradient(to right, var(--maincolor), var(--scndcolor));
    border: none;
    color: #fff;
    font-weight: 800;
    font-size: 20px;
    padding: 10px 0;
    border-radius: 5px;
    width: 50%;
}





.reg-form-sec .right-ad
{
    display: flex;
    align-items: start;
}
.reg-form-sec .right-ad img
{
    border-radius: 20px;
}




@media screen and (max-width: 1536px)
{
    .reg-form-sec .input-div .label
    {
        font-size: 14px !important;
    }
}
@media only screen and (max-width: 992px)
{
    .reg-form-sec .form-section form
    {
        margin-top: 40px;
    }
    .reg-form-sec .form-section
    {
        padding: 20px 40px;
    }
    
}

@media only screen and (max-width: 768px)
{
    .reg-form-sec .form-groups .row
    {
        gap:10px;
    }
    .reg-form-sec .form-section
    {
        padding: 50px 30px;
    }
    .reg-form-sec .form-section .form-groups h3
    {
        font-size: 18px;
    }
    .reg-form-sec .form-section .form-groups
    {
        gap:10px;
    }
    .reg-form-sec .form-section form
    {
        gap: 60px;
    }

    .reg-form-sec .input-div .label
    {
        font-size: 16px;
    }
    .reg-form-sec .input-div input::placeholder
    {
        color: #04285b4a;
        font-size: 14px;
    }
    .reg-form-sec .form-section form button
    {
        width: 100%;
        font-size: 18px;
    }
}